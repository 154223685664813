<!--
File: UnitEditForm.vue
Description: form for adding/editing a single measurement unit.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[{ 'md-valid': !errors.has('description_en') }, { 'md-error': errors.has('description_en') }]">
                <label>{{ $t('stdCols.name_en') }}</label>
                <md-input v-model="description_en" type="description_en" data-vv-name="description_en" required
                  v-validate="modelValidations.description_en"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field 
                :class="[{ 'md-valid': !errors.has('description_ru') }, { 'md-error': errors.has('description_ru') }]">
                <label>{{ $t('stdCols.name_ru') }}</label>
                <md-input v-model="description_ru" type="description_ru" data-vv-name="description_ru" required
                  v-validate="modelValidations.description_ru"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[{ 'md-valid': !errors.has('description_kg') }, { 'md-error': errors.has('description_kg') }]">
                <label>{{ $t('stdCols.name_kg') }}</label>
                <md-input v-model="description_kg" type="description_kg" data-vv-name="description_kg" required
                  v-validate="modelValidations.description_kg"></md-input>
              </md-field>
            </div>
          </div>
          
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'unit-edit-form',
    mixins: [messagesMixin],

    data() {
      return {
        unit_id: null,
        description_en: null,
        description_ru: null,
        description_kg: null,

        modelValidations: {
          description_en: { required: true, min: 1 },
          description_ru: { required: true, min: 1 },
          description_kg: { required: true, min: 1 }
        }
      }
    },

    props: {
      oper: String
    },

    components: {
    },

    mounted() {
      const { unit_id = null } = this.$route.params
      this.unit_id = unit_id
      if (this.oper === 'upd' && unit_id) {
        this.$store.dispatch('LOAD_UNIT_BY_ID', unit_id).then((res) => {
          const theUnit = Array.isArray(res) ? res[0] : res
          this.description_en = theUnit.description_en
          this.description_ru = theUnit.description_ru
          this.description_kg = theUnit.description_kg
        })
      }
      this.$nextTick(() => {
        this.$validator.validateAll()
      })
    },

    methods: {
      onClose,

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theUnit = {
          description_en: this.description_en,
          description_ru: this.description_ru,
          description_kg: this.description_kg
        }

        const payload = this.oper === 'add' ? theUnit : { id: this.unit_id, theUnit }
        let errDesc = ''
        try {
          await this.$store.dispatch(`${this.oper.toUpperCase()}_UNIT`, payload)
        } catch (err) {
          errDesc = err.message
        }
        this.savedMessage(errDesc, this.$t('label.units'), this.description_ru, this.description_en)
        this.onClose()
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    },

    watch: {
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
